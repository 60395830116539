:root{
    --themeclr: #8b36e0;
    --textclr: #833bcc;
    --blackclr: #000;
    --whtclr: #fff;
    --fonthead: 'vendin';
}
@font-face {
    font-family: 'vendin';
    src: url('../../fonts/Vendin.ttf');
}




.contentbox h5{
  word-break: break-all;
  word-wrap: break-word;
}
.jodit-react-container{
    color: #000 !important;
}
.jodit-react-container p{
    color: #000 !important;
}

.up_img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}
.otps div{
    gap: 8px;
}
.otps input {
    width: 2.5em !important;
    height: 2.5em;
    border: 1px solid #833bcc7d !important;
    background-color: var(--blackclr);
    color: var(--whtclr) !important;
}
.userlist{
    background-color: #000;
    padding: 8px 10px;
    border-radius: 4px;
    line-height: 1.8;
    cursor: pointer !important;
}
.loglist{
    color: var(--whtclr) !important;
    text-decoration: none !important;
}
.loglist:hover{
    color: var(--textclr) !important;
}
.h_auto::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.h_auto::-webkit-scrollbar
{
	width: 9px;
    height: 9px;
	background-color: transparent;
}

.h_auto::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--textclr);
}
.h_auto{
    height: 460px;
    min-height: 460px;
    overflow: auto;
    padding-right: 10px;
}
.blacktxtimg{
   background: #040303;
    width: 360px;
    min-width: 360px;
    border-radius: 6px;
    margin-right: auto;
  }
  .viotxtimg{
  background: var(--themeclr);
    width: 360px;
    min-width: 360px;
    border-radius: 6px;
    margin-left: auto;
  }
.micon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: contain;
}
.starimg{
    width: 18px !important;
    height: 18px !important;
}
.cardorder{
    background-color: #000;
    border-radius: 6px;
}
.cardimg{
    height: 310px;
    min-height: 310px;
    object-fit: cover;
    width: 100%;
    border-radius: 6px;
}
.listadmin,.orderss{
    display: flex;
    padding-left: 0px;
    gap: 25px;
}
.orderss li{
    text-transform: uppercase;
    list-style-type: none;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
}
.orderss li:hover, .orderss li.active{
    color: var(--themeclr);
    border-bottom: 1px solid var(--themeclr);
    transition: 0.3s;
}
.listadmin li{
    list-style-type: none;
    background-color: #000;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
}
.listadmin li:hover,.listadmin li.active,.userlist:hover{
    background-color: var(--themeclr);
}
.forgotclr{
    color: var(--themeclr);
    text-decoration: underline;
    cursor: pointer;
    text-align: end;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #833bcc7d;
  -webkit-text-fill-color: var(--whtclr);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.auth form .form-group .form-control{
   background-color: var(--blackclr) !important;  
}
.sidebar{
    min-height: 100vh !important;
}
.dropdown .dropdown-menu{
    border: 1px solid #833bcc7d !important;
    background-color: var(--blackclr) !important;
}
.form-control,.form-control:focus{
    border: 1px solid #833bcc7d !important;
    background-color: var(--blackclr);
    color: var(--whtclr) !important;
}
.sidebar .nav .nav-item.active > .nav-link:before{
    background-color: var(--whtclr) !important;
}
.sidebar .nav .nav-item.active > .nav-link{
    background-color: var(--textclr) !important;
    color: var(--whtclr) !important;
}
.card .card-title, .vendin{
    font-family: var(--fonthead);
}
.sidebar .nav.sub-menu{
    padding: 0 0 0 2.25rem !important;
}
.sidebar{
    border-right: 2px solid var(--themeclr);
}
.vaild_clr{
    color: red;
}
.grnbtn,.grnbtn:focus{
    background-color: green !important;
    border: 1px solid green !important;
    box-shadow: unset !important;
    color: #fff !important;
    border-radius: 6px;
    font-weight: 500;
}

.redbtn,.redbtn:focus{
    background-color: red !important;
    border: 1px solid red !important;
    box-shadow: unset !important;
    color: #fff !important;
    border-radius: 6px;
    font-weight: 500;
}
.allbtns.redbtn:hover, .allbtns.grnbtn:hover{
    color: #fff !important;
}
.close {
    color: #fff !important;

}
.yelobtn,.yelobtn:focus{
    background-color: yellow !important;
    border: 1px solid yellow !important;
    box-shadow: unset !important;
    color: #000 !important;
    font-weight: 500;
    border-radius: 6px;
}

.border_card{
  border-radius: 8px;
}
.dashhead{
   color: var(--whtclr);
   font-family: var(--fonthead) !important;
}
.textthmclr{
    color: var(--textclr);
}
.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}
.userlistss tbody tr td{
   padding: 0px 12px;
}
/* .form-control:focus {
    color: #fff !important;
    border: 1px solid rgb(147 130 177 / 61%) !important;
} */
/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
    cursor: pointer;
}

select option {
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer !important;
    color: #fff !important;
}

.asrt-pagination li {
    cursor: pointer;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover{
  color: var(--textclr);
}
.passwordinput .form-control {
    background-color: var(--blackclr);
}

.passwordinput .btn-outline-primary {
    color: var(--whtclr);
    border-color: #833bcc7d;
    background-color: var(--blackclr);
    padding: 10px 10px;
}

.passwordinput .btn-outline-primary:hover {
    background-color: transparent !important;
}
.footer a{
    color: var(--themeclr) !important;
}
.asrt-page-length .input-group-addon{
    background: unset !important;
}
.asrt-page-length select{
    color: #fff !important;
}
.input-group-prepend span{
    color: #fff !important;
}
.asrt-table-foot{
    margin-top: 2%;
}
.page-item.disabled .page-link {
    color: var(--whtclr) !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff0 !important;
    border-color: var(--whtclr) !important;
}
.page-item.disabled .page-link:hover{
    background-color: var(--themeclr) !important;
    color: #fff !important;
}
.as-react-table .pagination li:hover a {
    background-color: var(--themeclr) !important;
    border: 1px solid var(--themeclr) !important;
    color: #fff!important;
}
.page-link {
    background-color: #00000000 !important;
    border: 1px solid var(--whtclr);
    color: var(--whtclr) !important;
}
.page-link input{
    background: unset !important;
    color: #fff !important;
}
.allbtn{
    background: var(--themeclr);
    border: 1px solid var(--themeclr);
    padding: 10px 16px;
}
.table thead th{
   line-height: 21px;
}
.allbtnlogout{
    background: red;
    border: 1px solid red;
    padding: 6px 14px;
    color: #fff;
    border-radius: 6px;
}
.allbtnlogout:hover{
    background: transparent;
    border: 1px solid red;
    color: red;
}
.allbtns{
    padding: 8px 16px !important; 
}
.css-yt9ioa-option {
    color: black !important; 
    /* background-color: var(--themeclr) !important; */
}
.css-1n7v3ny-option {
    /* background-color: var(--themeclr) !important; */
}
.pos{
    position: relative;
    z-index: 4;
}
.setimg{
    width: 50px;
    opacity: 0;
}
.setattach{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 38px;
}
.sendimg
{
    border: 1px solid gray;
    border-radius: 4px;
    padding: 4px;
    width: 100px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sendimg img{
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.wimg{
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.closesvg{
    position: absolute;
    right: -1px;
    top: -15px;
}
.NFT.IDT{
    width: 580px !important;
    max-width: 580px !important;
    word-wrap: break-word !important;
}
.NFT.IDT p{
    line-height: normal;
}

.css-6c09z3-option{
    color:#000 !important;
}
.css-1gj9k8-option{
    background-color: unset !important;
    color: #000 !important;
}
.css-zjsb6u-option{
    background-color: var(--themeclr) !important;
}

.css-1n7v3ny-option {
    background-color: unset !important;
    color: black !important;
   /* background-color: transparent !important;
   color: black !important; */
}
.css-1pahdxg-control:hover{
    border-color: white !important;
}
.css-9gakcf-option {
    background-color: var(--themeclr) !important;
}
/* .css-9gakcf-option {
    background-color: transparent !important;
    color: black !important;
} */

.allbtn:hover{
    background: transparent;
    border: 1px solid var(--themeclr);
    color: var(--themeclr) !important;
}
.icon.icon-box-success {
    background: rgb(130 101 182 / 24%) !important;
}
.icon .icon-item {
    color: var(--themeclr);
}
.ReactModal__Overlay {
    background-color: #00000091 !important;
}
.ReactModal__Content{
    border: 1px solid #808080a8 !important;
    background: rgb(11 12 15) !important;
    border-radius: 16px !important;
}
.ReactModal__Content h2{
    color: var(--textclr) !important;
}
.closebtn{
    position: absolute;
    right: 8px;
    top: 4px;
    font-size: 19px;
    font-weight: 500;
}
.closebtn:hover{
   color: var(--themeclr);
}
.table-responsive{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--themeclr);
}
.faq .as-react-table{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
.faq .as-react-table::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--themeclr);
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}
.dash-token{
    color: var(--themeclr) !important;
}
.editservice{
    font-size: 15px;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
    white-space: normal;
    line-height: 20px;
}
/* th.sortable {
    color: var(--themeclr) !important;
    font-weight: 600 !important;
} */

@media only screen and (min-width: 1700px) {
.filterdatabox {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    gap: 18px;
}
}

@media only screen and (min-width: 1200px) and (max-width: 1699px) {
.filterdatabox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
}}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .sidebar .nav{padding-top: 70px;}
    .filterdatabox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 991px) {
    .navbar-menu-wrapper.flex-grow{
      flex-grow: unset !important;
    }
    .filterdatabox {
        grid-template-columns: repeat(2, 1fr);
    }
    }


@media only screen and (max-width: 767px) {
     .table-responsive::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
.faq .as-react-table::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
    .table-responsive{
        padding-bottom: 8%;
    }
    .faq .as-react-table{
        padding-bottom: 8%;
    }
    .pagination {
        justify-content: unset !important;
    }
    .pagination li{
        font-size: 11px !important;
        margin-top: 10px;
    }
    .table_filter input{
       margin-top: 14px;
    }
   .asrt-page-length .input-group-text{
       font-size: 11px;
    }
    .asrt-page-length select.form-control {
        padding: 0.4375rem 0.45rem !important;
        width: 53px !important;
        font-size: 12px !important;
    }
    .asrt-table-head .text-right{
        text-align: left !important;
    }
    .asrt-table-foot>div:first-child {
       font-size: 12px;
       margin-top: 6px;
    }
  }
  .navbar.loginnavbar{
    left: 0px !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after, .btn-primary:not(.btn-light):not(.btn-secondary):active, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
    background: var(--themeclr) !important;
    border-color: var(--themeclr) !important;
    color: #fff !important;
}

.referralpages .btnsec{
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.referralpages .add_btnsec{
    margin-left: 15px;
    margin-top: 10px;
}
.referralpages .allbtn{
    width: 100px;
}

@media only screen and (max-width: 767px) {
    .referralpages .add_btnsec{
        margin-left:12px;
    }
    .referralpages .btnsec{
        margin-bottom: 15px;
    }
}


@media only screen and (max-width: 575px) {
.listadmin{
    display: unset;
}
.filterdatabox{
    grid-template-columns: repeat(1, 1fr);
}
.blacktxtimg, .viotxtimg{
    width: 250px;
    min-width: 250px;
}
}
